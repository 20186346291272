import { Validation } from "@vuelidate/core"
import { FetchError } from "ofetch"

export const useVuelidateUtils = () => {
  const { $R } = useNuxtApp()
  const { collectErrorMessages } = useAPIUtils()

  const getInitialState = <Form = VuelidateForm>(transformations: Record<string, (...args: any[]) => any>, item: SingaAPIObject) =>
    $R.applySpec(transformations)(item) as Form

  const getErrorMessages = <FormT = VuelidateForm>($v: Validation) => $R.pipe(
    $R.pickBy(isFormKey),
    $R.mapObjIndexed(
      $R.pipe(
        $R.prop('$errors'),
        $R.pluck('$message')
      )
    )
  )($v) as VuelidateFormErrors<FormT>

  const getExternalErrorMessages = <FormT>(err: FetchError) =>
    collectErrorMessages(err) as VuelidateFormErrors<FormT>

  const isFormKey = (_: any, key: string) =>
    $R.complement($R.startsWith('$'))(key)

  return {
    getInitialState,
    getErrorMessages,
    getExternalErrorMessages
  }
}
